import React, { useState } from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import { createNewEntry, updateEntry } from "./utils/apiCalls";
import Typography from "@material-ui/core/Typography";
import { errorToString } from "./utils/utilFunctions";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  keyInput: {
    paddingRight: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  deleteButton: {
    position: 'absolute',
    padding: 0,
    paddingTop: theme.spacing(2),
    right: theme.spacing(0),
    top: theme.spacing(0),
  },
  valueInput: {
    position: 'relative',
    width: "100%",
    marginBottom: theme.spacing(3),
    paddingRight: theme.spacing(4),
  },
  buttonPadder: {
    flexGrow: 1
  },
}));

export default function EntryEditDialog({ uuid, entry, onClose, onEditSuccess, onNewSuccess }) {
  const classes = useStyles();
  const [key, setKey] = useState(entry.key);
  const [values, setValues] = useState([...entry.values]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deleted, setDeleted] = useState(new Array(entry.values.length).fill(false));

  const isDiff = (() => {
    if (entry.key !== key) {
      return true;
    }
    const actualValues = values.filter((v, i) => !deleted[i]);
    if (actualValues.length !== entry.values.length) {
      return true;
    }
    for (let i = 0; i < actualValues.length; i += 1) {
      if (actualValues[i] !== entry.values[i]) {
        return true;
      }
    }
    return false;
  })();

  const onKeyChange = (event) => {
    setKey(event.target.value);
  }
  const onValueChange = (index, val) => {
    const newValues = [...values];
    while (newValues.length <= index) {
      newValues.push(val);
    }
    newValues[index] = val;
    setValues(newValues);
  }
  const addNewValue = () => {
    const newValues = [...values];
    newValues.push("");
    const newDeleted = [...deleted];
    newDeleted.push(false);

    setDeleted(newDeleted);
    setValues(newValues);
  }

  const deleteValue = (idx) => {
    const newDeleted = [...deleted];
    newDeleted[idx] = true;
    setDeleted(newDeleted);
  }

  const handleSave = () => {
    setLoading(true);
    const actualValues = values.filter((v, i) => !deleted[i]);
    if (entry.id >= 0) {
      updateEntry(uuid, entry.id, key, actualValues)
        .then(() => {
          setLoading(false);
          onEditSuccess();
        })
        .catch((err) => {
          setLoading(false);
          setError(err);
        });
    } else {
      createNewEntry(uuid, key, actualValues)
        .then((newId) => {
          setLoading(false)
          onNewSuccess(newId);
        })
        .catch((err) => {
          setLoading(false);
          setError(err);
        })
    }
  }
  const inputDisabled = Boolean(error || loading);
  const saveButtonDisabled = Boolean(error || loading || !key || !isDiff);
  let saveButtonText = entry.id >= 0 ? "Save" : "Save New";
  if (error) {
    saveButtonText = "Error";
  } else if (loading) {
    saveButtonText = "Saving";
  } else if (!key) {
    saveButtonText = "Need Key";
  } else if (!isDiff) {
    saveButtonText = "No Change";
  }

  return (
    <Dialog open onClose={onClose} fullWidth>
      <DialogTitle disableTypography className={classes.root}>
        <TextField
          variant="outlined"
          className={classes.keyInput}
          required
          label="Key Name"
          defaultValue={key}
          onChange={onKeyChange}
          fullWidth
          disabled={inputDisabled}
        />
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {
          error &&
          <Typography gutterBottom>
            {errorToString(error)}
          </Typography>
        }
        {
          values.map((v, i) => (
            !deleted[i] && (
              <div className={classes.valueInput} key={entry.id + "-" + i}>
                <TextField
                  variant="outlined"
                  fullWidth
                  defaultValue={v}
                  onChange={(event) => onValueChange(i, event.target.value)}
                  disabled={inputDisabled}
                />
                <IconButton
                  aria-label="deletevalue"
                  className={classes.deleteButton}
                  disabled={inputDisabled}
                  onClick={() => deleteValue(i)}>
                  <DeleteForeverIcon />
                </IconButton>
              </div>
            )
          ))
        }
        <Button
          disabled={inputDisabled}
          onClick={addNewValue}
        >
          + New Value
        </Button>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttonPadder} />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={saveButtonDisabled}
        >
          {saveButtonText}
        </Button>
        <div className={classes.buttonPadder} />
        <div className={classes.buttonPadder} />
        <Button variant="contained" onClick={onClose}>
          Cancel
        </Button>
        <div className={classes.buttonPadder} />
      </DialogActions>
    </Dialog>
  );
}

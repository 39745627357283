import axios from 'axios';

const BASE_URL = "https://epaingo.com/myentry/api";

export function getOwner(uuid) {
  const url = `${BASE_URL}/auth`;
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const data = { uuid };
  return axios.post(url, data, config);
}

export function getEntries(uuid) {
  const url = `${BASE_URL}/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const data = { uuid };
  return axios.post(url, data, config).then(
    (res) => {
      if (res.data === null || !Array.isArray(res.data)) {
        throw Error("malformed response from getEntries");
      }
      return res.data.map((entry) => {
        const kv = JSON.parse(entry.jsonVal);
        entry.key = kv.key;
        entry.values = kv.values;
        return entry;
      }).reverse();
    },
  );
}

export function createNewEntry(uuid, key, values) {
  const url = `${BASE_URL}/entry`;
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const data = { uuid, key, values};
  return axios.post(url, data, config).then(
    (res) => {
      if (res.data === null
        || typeof res.data !== 'object'
        || !res.data.new_id
        || !Number.isInteger(res.data.new_id)) {
        throw Error("malformed response from getEntries");
      }
      return res.data.new_id;
    },
  );
}

export function updateEntry(uuid, entryID, key, values) {
  const url = `${BASE_URL}/entry/${entryID}`;
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const data = {
    uuid,
    key,
    values,
  };
  return axios.post(url, data, config);
}

export function deleteEntry(uuid, entryID) {
  const url = `${BASE_URL}/entry/${entryID}`;
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    data: { uuid },
  };
  return axios.delete(url, config);
}

import React, { useState } from 'react';
import './App.css';
import MyEntryMain from "./MyEntryMain";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { getOwner } from "./utils/apiCalls";
import Typography from "@material-ui/core/Typography";
import { errorToString } from "./utils/utilFunctions";
import logo from "./circlelogo400.png"

const LS_UUID_KEY = "myentry_uuid";
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  loadingIndicator: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(3),
  },
  container: {
    padding: 0,
  },
  inputArea: {
    display: "flex",
    flexFlow: "column",
    minHeight: "80vh",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputContent: {
    maxWidth: "400px",
    justifyContent: 'center',
  },
  logo: {
    width: "50%",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(2),
    display: "block",
  },
  button: {
    marginTop: theme.spacing(2),
    width: '100%',
  }
}));

function App() {
  const classes = useStyles();
  const [uuid, setUUID] = useState(localStorage.getItem(LS_UUID_KEY));
  const [inputUUID, setInputUUID] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onUUIDChange = (event) => {
    setInputUUID(event.target.value);
  }

  const handleStart = (event) => {
    event.preventDefault();
    if (!inputUUID || loading) {
      return;
    }
    setLoading(true);
    setError(null);
    getOwner(inputUUID)
      .then(() => {
        localStorage.setItem(LS_UUID_KEY, inputUUID);
        setInputUUID("");
        setLoading(false);
        setUUID(inputUUID);
      })
      .catch((err) => {
        setUUID(null);
        setLoading(false);
        setError(err);
      })
  }

  if (uuid) {
    return (
      <MyEntryMain
        uuid={uuid}
        onSignOut={() => {
          localStorage.removeItem(LS_UUID_KEY);
          setUUID(null);
        }}
      />
    );
  }

  if (loading) {
    return (
      <div className={classes.loadingIndicator}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Container className={classes.container} maxWidth="sm">
      <div className={classes.inputArea}>
        <div className={classes.inputContent}>
          <img className={classes.logo} alt="logo" src={logo} />
          <form onSubmit={handleStart}>
            <TextField
              required
              autoFocus
              label="Access Code"
              onChange={onUUIDChange}
              fullWidth
              type="password"
            />
            <Button
              color="primary"
              className={classes.button}
              variant="contained"
              disabled={!inputUUID || loading}
              onClick={handleStart}
            >
              Start My Entry
            </Button>
            {
              error &&
              <>
                <Typography>No Access</Typography>
                <Typography>{errorToString(error)}</Typography>
              </>
            }
          </form>
        </div>
      </div>
    </Container>
  )
    ;
}

export default App;

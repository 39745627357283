import React, { useEffect, useState } from 'react';
import { getEntries } from "./utils/apiCalls";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import { fade, makeStyles } from "@material-ui/core/styles";
import { errorToString } from "./utils/utilFunctions";
import Container from "@material-ui/core/Container";
import AddBoxIcon from '@material-ui/icons/AddBox';
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import EntryReadDialog from "./EntryReadDialog";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import EntryEditDialog from "./EntryEditDialog";


const PAGINATION_CHUNK_SIZE = 20;
const DRAWER_ID = "myentry-drawer-id";
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  search: {
    flexGrow: 1,
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchInputRoot: {
    color: 'inherit',
    width: '100%',
  },
  searchInputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  loadingIndicator: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(3),
  },
  container: {
    padding: 0,
  },
  moreButton: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
}));

export default function MyEntryMain({ uuid, onSignOut }) {
  const [loading, setLoading] = useState(true);
  const [entries, setEntries] = useState([]);
  const [error, setError] = useState(null);
  const [drawerAnchor, setDrawerAnchor] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [numEntriesToShow, setNumEntriesToShow] = useState(PAGINATION_CHUNK_SIZE);
  const [dialogInfo, setDialogInfo] = useState({ entry: null, mode: "view" });
  const [priorityEntry, setPriorityEntry] = useState(null);
  const classes = useStyles();

  const loadEntries = (uuidArg, priorityID, priorityDialogMode) => {
    setLoading(true);
    getEntries(uuidArg)
      .then((result) => {
        if (priorityID !== undefined) {
          const priorityEntry = result.find((entry) => entry.id === priorityID);
          if (priorityEntry) {
            setPriorityEntry(priorityEntry);
            if (priorityDialogMode) {
              setDialogInfo({
                entry: priorityEntry,
                mode: priorityDialogMode,
              });
            }
          }
        }
        setEntries(result);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  };

  useEffect(() => {
      loadEntries(uuid);
    },
    [uuid],
  );

  // Short Circuitings
  if (loading) {
    return (
      <div className={classes.loadingIndicator}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return <Typography>{errorToString(error)}</Typography>
  }

  // Handlers
  const onSearchChange = (event) => {
    setNumEntriesToShow(PAGINATION_CHUNK_SIZE);
    setSearchKeyword(event.target.value);
  };

  const handleDrawerOpen = (event) => {
    setDrawerAnchor(event.currentTarget);
  };
  const handleDrawerClose = () => {
    setDrawerAnchor(null);
  };

  const fullReset = (priorityID, priorityDialogMode) => {
    setError(null);
    setDialogInfo({ entry: null, mode: "view" });
    setDrawerAnchor(null);
    setNumEntriesToShow(PAGINATION_CHUNK_SIZE);
    setSearchKeyword("");
    setPriorityEntry(null);
    loadEntries(uuid, priorityID, priorityDialogMode);
  }

  const handleAddNewEntry = () => {
    const emptyEntry = {
      id: -1,
      key: "",
      values: [""],
    }
    setDialogInfo({
      entry: emptyEntry,
      mode: "new",
    })
  }

  let showMoreButton = false;
  let count = 0;
  const entriesToRender = [];
  for (let i = 0; i < entries.length; i += 1) {
    if (searchKeyword === ""
      || entries[i].key.toLowerCase().includes(searchKeyword.toLocaleLowerCase())) {
      count += 1;
      if (priorityEntry && entries[i].id === priorityEntry.id) {
        entriesToRender.unshift(entries[i]);
      } else if (count <= numEntriesToShow) {
        entriesToRender.push(entries[i]);
      } else {
        showMoreButton = true;
      }
    }
  }

  let dialog = null;
  if (dialogInfo.entry) {
    if (dialogInfo.mode === "view") {
      dialog = (
        <EntryReadDialog
          entry={dialogInfo.entry}
          onClose={() => {
            setDialogInfo({
              entry: null,
              mode: "view",
            });
          }}
          handleDelete={() => {
            setDialogInfo({
              entry: dialogInfo.entry,
              mode: "delete",
            });
          }}
          handleEdit={() => {
            setDialogInfo({
              entry: dialogInfo.entry,
              mode: "edit",
            });
          }}
        />
      );
    }
    if (dialogInfo.mode === "delete") {
      dialog = (
        <DeleteConfirmationDialog
          uuid={uuid}
          entry={dialogInfo.entry}
          onClose={() => {
            setDialogInfo({
              entry: null,
              mode: "view"
            });
          }}
          onDeleteSuccess={() => fullReset()}
        />
      );
    }
    if (dialogInfo.mode === "edit") {
      dialog = (
        <EntryEditDialog
          uuid={uuid}
          entry={dialogInfo.entry}
          onClose={() => {
            setDialogInfo({
              entry: null,
              mode: "view"
            });
          }}
          onEditSuccess={() => {
            setDialogInfo({ entry: null, mode: "view" });
            loadEntries(uuid, dialogInfo.entry.id, "view");
          }}
        />
      );
    }
    if (dialogInfo.mode === "new") {
      dialog = (
        <EntryEditDialog
          uuid={uuid}
          entry={dialogInfo.entry}
          onClose={() => {
            setDialogInfo({
              entry: null,
              mode: "view"
            });
          }}
          onNewSuccess={(newId) => {
            fullReset(newId, "view");
          }}
        />
      );
    }
  }

  return (
    <Container className={classes.container}>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            aria-controls={DRAWER_ID}
            aria-haspopup="true"
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              autoFocus={true}
              placeholder="Search…"
              defaultValue={searchKeyword}
              classes={{
                root: classes.searchInputRoot,
                input: classes.searchInputInput,
              }}
              inputProps={{ 'aria-label': 'search' , autoFocus: true}}
              onChange={onSearchChange}
            />
          </div>
          <IconButton
            edge="end"
            aria-label="Add New Entry"
            color="inherit"
            onClick={handleAddNewEntry}
          >
            <AddBoxIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={drawerAnchor}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        id={DRAWER_ID}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={Boolean(drawerAnchor)}
        onClose={handleDrawerClose}
      >
        <MenuItem onClick={fullReset}>
          Refresh
        </MenuItem>
        <MenuItem onClick={onSignOut}>Sign Out</MenuItem>
      </Menu>
      <TableContainer>
        <Table style={{
          tableLayout: "fixed",
          width: "100%"
        }}>
          <TableBody>
            {
              entriesToRender.map((entry) => {
                return (
                  <TableRow
                    key={entry.id}
                    onClick={() => {
                      setDialogInfo({ entry, mode: "view" });
                    }}
                  >
                    <TableCell>
                      <Typography noWrap>
                        {entry.key}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography noWrap>
                        {
                          entry.values.map((v, i) => (
                            <span key={i}>
                              {v}<br />
                            </span>
                          ))
                        }
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
      {
        showMoreButton && (
          <div className={classes.moreButton}>
            <Button
              color="primary"
              variant="text"
              onClick={() => {
                setNumEntriesToShow(numEntriesToShow + PAGINATION_CHUNK_SIZE)
              }}
            >
              Show More
            </Button>
          </div>
        )
      }
      {dialog}
    </Container>
  );
}

export function errorToString(err) {
  if (err === null) {
    return '';
  }
  if (err instanceof Error) {
    return err.message;
  }
  if (err instanceof String) {
    return err.toString();
  }
  if (typeof err === 'string') {
    return err;
  }
  if (err instanceof Object) {
    return err.toString();
  }
  return 'indecipherable error';
}


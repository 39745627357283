import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  title: {
    paddingRight: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  buttonPadder: {
    flexGrow: 1
  },
}));
export default function EntryReadDialog({ entry, onClose, handleEdit, handleDelete }) {
  const classes = useStyles();
  return (
    <Dialog open onClose={onClose} fullWidth>
      <DialogTitle disableTypography className={classes.root}>
        <Typography variant="h6" className={classes.title}>{entry.key}</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {
          entry.values.map((v, i) => (
            <Typography key={i} gutterBottom>
              {v}
            </Typography>
          ))
        }
      </DialogContent>
      <DialogActions>
        <div className={classes.buttonPadder} />
        <Button variant="contained" color="secondary" onClick={handleDelete}>
          Delete
        </Button>
        <div className={classes.buttonPadder} />
        <div className={classes.buttonPadder} />
        <Button variant="contained" color="primary" onClick={handleEdit}>
          Edit
        </Button>
        <div className={classes.buttonPadder} />
      </DialogActions>
    </Dialog>
  );
}
